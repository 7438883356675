/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import Login from './pages/Login';
import Layout from './pages/Layout';
import LinensTowels from './pages/LinensTowels';
import Settings from './pages/settings/Settings';
import NotFound from './pages/NotFound';
import Vendor from './pages/inventory/Vendor';
import AddItem from './pages/inventory/AddItem';
import Confirm from './pages/inventory/Confirm';
import Items from './pages/inventory/Items';
import EditItem from './pages/inventory/EditItem';
import Stock from './pages/inventory/Stock';
import EditCount from './pages/inventory/EditCount';
import StockCount from './pages/inventory/StockCount';
import DeliveriesAlerts from './pages/inventory/DeliveriesAlerts';
import PreviousStockCounts from './pages/inventory/PreviousStockCounts';
import Overview from './pages/Overview';
import Team from './pages/Team';
import OpsProcedures from './pages/OpsProcedures';
import Maintenance from './pages/Maintenance';
import Reservations from './pages/reservations/Reservations';
import ReservationDetails from './components/reservations/ReservationDetails';
import LockDevices from './pages/lockDevices/LockDevices';
import DeviceDetails from './pages/lockDevices/DeviceDetails';
import RequireAuth from './RequireAuth';
import Guests from './pages/guests/Guests';
import GuestDetails from './pages/guests/GuestsDetails';
import ReservationProducts from './pages/reservationProducts/ReservationProducts';
import DoorLocks from './pages/doorLocks/DoorLocks';

const Navigation = observer(() => (
  <Routes>
    <Route element={<Layout />}>
      <Route path={'/'} element={<Login />} />
      <Route path={'*'} element={<NotFound />} />
    </Route>
    <Route
      path={'/'}
      element={
        <RequireAuth>
          <Layout />
        </RequireAuth>
      }
    >
      <Route path={'/overview'} element={<Overview />} />
      <Route path={'/linens-towels'} element={<LinensTowels />} />
      <Route path={'inventory'}>
        <Route path={'stock'} element={<Stock />} />
        <Route path={'stock-count'} element={<StockCount />} />
        <Route path={'previous/stock-counts'} element={<PreviousStockCounts />} />
        <Route path={'edit-count/:countId'} element={<EditCount />} />
        <Route path={'deliveries-alerts'} element={<DeliveriesAlerts />} />
        <Route path={'items'} element={<Items />} />
        <Route path={'edit-item/:itemId'} element={<EditItem />} />
        <Route path={'add-item'} element={<AddItem />} />
        <Route path={'confirm'} element={<Confirm />} />
        <Route path={'vendor'} element={<Vendor />} />
      </Route>
      <Route path={'/travelers'}>
        <Route path={'reservations'} element={<Reservations />} />
        <Route
          path={'reservations/:reservationGroup/:checkInDate'}
          element={<ReservationDetails />}
        />
        <Route path={'reservation-products'} element={<ReservationProducts />} />
        <Route path={'access'} element={<LockDevices />} />
        <Route path={'access/device/:deviceName/:deviceId'} element={<DeviceDetails />} />
        <Route path={'guests'} element={<Guests />} />
        <Route path={'guests/:guestId'} element={<GuestDetails />} />
      </Route>
      <Route path={'/door-locks'} element={<DoorLocks />} />
      <Route path={'/team'} element={<Team />} />
      <Route path={'/procedures'} element={<OpsProcedures />} />
      <Route path={'/maintenance'} element={<Maintenance />} />
      <Route path={'settings'} element={<Settings />} />
      <Route path={'*'} element={<NotFound />} />
    </Route>
  </Routes>
));

export default Navigation;
