import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import SelectHotel from '../../components/Navbar/SelectHotel';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import Header from '../../components/table/Header';
import CardRowItem from '../../components/table/CardRowItem';
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import {useTranslation} from 'react-i18next';
import NoResultsFound from '../../components/general/NoResultsFound';
import '../../styles/main.css';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import ListPagination from '../../components/ListPagination';
import LoadingButton from '@mui/lab/LoadingButton';

const LockDevices = observer(() => {
  const {t} = useTranslation();
  const {lockDevicesStore, hotelStore} = useRootStore();
  const [buildingCode, setBuildingCode] = useState('');

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      lockDevicesStore.getDevices();

      const doorLock = hotelStore.currentHotel.hotel_door_lock;
      setBuildingCode(doorLock ?? '');
    }
  }, [hotelStore.currentHotel]);

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const saveBuildingCodeHandler = () => {
    hotelStore.saveBuildingCode(buildingCode);
  };

  const clearBuildingCodeHandler = () => {
    hotelStore.saveBuildingCode('');
  };

  const onChangeBuildingCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuildingCode(event.target.value);
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <PageTitle>{t('common:navbar_lock_devices')}</PageTitle>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
          >
            <SelectHotel disabled={lockDevicesStore.isLoading} />
            <TextField
              name="building_code"
              value={buildingCode}
              label={t('common:building_code')}
              onChange={onChangeBuildingCode}
              fullWidth
            />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={saveBuildingCodeHandler}
              sx={{paddingBlock: '1rem', alignSelf: {sm: 'end'}}}
              disabled={
                buildingCode === '' || buildingCode === hotelStore?.currentHotel?.hotel_door_lock
              }
            >
              Save
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={clearBuildingCodeHandler}
              sx={{paddingBlock: '1rem', alignSelf: {sm: 'end'}}}
              disabled={hotelStore?.currentHotel?.hotel_door_lock === ''}
            >
              Delete
            </LoadingButton>
          </Grid>
        </Grid>

        <Card
          elevation={isDesktop ? 2 : 0}
          sx={{
            marginTop: 5,
            backgroundColor: {xs: 'transparent', lg: '#FFF'},
            overflow: 'visible',
          }}
        >
          <CardContent sx={{padding: {xs: 0, lg: 2}}}>
            <Typography variant="h5" component="h5" marginBottom={3}>
              {t('lockDevices:devices')}
            </Typography>

            {lockDevicesStore.isLoading && <SkeletonLoader skeletonShape="table" />}

            {lockDevicesStore.count > 0 && !lockDevicesStore.isLoading && (
              <>
                <Grid container display={{xs: 'none', lg: 'flex'}} mt={3}>
                  <Header lg={2}>{t('common:name')}</Header>
                  <Header lg={2}>{t('lockDevices:room_number')}</Header>
                  <Header lg={2}>{t('lockDevices:online')}</Header>
                  <Header lg={2}>{t('lockDevices:battery_level')}</Header>
                  <Header lg={2}>{t('lockDevices:locked')}</Header>
                  <Header lg={2}>{t('common:actions')}</Header>
                </Grid>
                <Grid container gap={{xs: 5, lg: 0}}>
                  {lockDevicesStore.devices.map((device, index) => {
                    let battery;
                    if (device.online) {
                      const batteryLevel = Math.floor(device.battery_level * 100);

                      const status =
                        batteryLevel < 30
                          ? {color: 'warning.light', fontWeight: 700}
                          : {color: '', fontWeight: 400};

                      battery = (
                        <Typography
                          variant="body1"
                          component="p"
                          {...status}
                        >{`${batteryLevel}%`}</Typography>
                      );
                    } else {
                      battery = '-';
                    }

                    const info = [
                      {
                        label: t('common:name'),
                        value: device.name,
                        lg: 2,
                        alignItems: 'center',
                      },
                      {
                        label: t('lockDevices:room_number'),
                        value: device.room_number,
                        lg: 2,
                        alignItems: 'center',
                      },
                      {
                        label: t('lockDevices:online'),
                        value: device.online ? 'Yes' : 'No',
                        lg: 2,
                        alignItems: 'center',
                      },
                      {
                        label: t('lockDevices:battery_level'),
                        value: battery,
                        lg: 2,
                        alignItems: 'center',
                      },
                      {
                        label: t('lockDevices:locked'),
                        value: device.locked ? 'Locked' : 'Unlocked',
                        lg: 2,
                        alignItems: 'center',
                      },
                      {
                        label: t('common:actions'),
                        value: (
                          <Link
                            to={`device/${device.name}/${device.device_id}`}
                            style={{textDecoration: 'none'}}
                          >
                            <Tooltip title="See details">
                              <IconButton aria-label="See details" color="primary">
                                <FormatListBulletedOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        ),
                        lg: 2,
                        alignItems: 'center',
                      },
                    ];
                    return <CardRowItem key={index} item={info} />;
                  })}
                </Grid>
              </>
            )}

            {lockDevicesStore.count > 0 && (
              <Stack
                display="flex"
                flexDirection={'row'}
                width="100%"
                justifyContent={'center'}
                marginTop={3}
              >
                <ListPagination
                  getter={lockDevicesStore.getDevices.bind(lockDevicesStore)}
                  store={lockDevicesStore}
                />
              </Stack>
            )}

            {lockDevicesStore.count === 0 && !lockDevicesStore.isLoading && (
              <NoResultsFound message={t('lockDevices:no_devices_registered_hotel')} />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
});

export default LockDevices;
