import React, {useState} from 'react';
import {Button, Stack, Typography, Box} from '@mui/material';
import Modal from '@mui/material/Modal';
import {observer} from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';

interface ICreateCodeModalProps {
  handleCreateCode: (e: React.SyntheticEvent, deviceId: string, codeName: string) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  deviceId: string;
}

const CreateCodeModal: React.FC<ICreateCodeModalProps> = observer(
  ({handleCreateCode, isOpen, onClose, deviceId}) => {
    const [codeName, setCodeName] = useState('');

    const {t} = useTranslation();

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.default',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const createCodeHandler = async (e: React.SyntheticEvent) => {
      onClose();
      await handleCreateCode(e, deviceId, codeName);
      setCodeName('');
    };

    return (
      <>
        <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            width={{xs: '70%', sm: 'auto'}}
            justifyContent="center"
            alignItems={'center'}
            display="flex"
            flexDirection={'column'}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('lockDevices:please_enter_a_name_for_new_code')}
            </Typography>
            <TextField
              id="outlined-basic"
              label="Code name"
              placeholder="64 Characters max."
              variant="outlined"
              margin="normal"
              value={codeName}
              onChange={(e) => setCodeName(e.target.value)}
              multiline={false}
              inputProps={{maxLength: 64}}
              fullWidth={true}
            />

            <Stack flexDirection="row" gap={1} justifyContent="flex-end" marginTop={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={createCodeHandler}
                disabled={codeName.length === 0}
              >
                {t('lockDevices:create_code')}
              </Button>
              <Button variant="contained" color="error" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  },
);

export default CreateCodeModal;
